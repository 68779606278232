import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    main: {
      principal: "#F29325",
      background: "#FFFFFF",
      text: "#222222",
      sideBar: "#333333"
    },
    gray: {
      50: "#f7fafc",
      100: "#edf2f7",
      200: "#e2e8f0",
      300: "#cbd5e0",
      400: "#a0aec0",
      500: "#718096",
      600: "#4a5568",
      700: "#2d3748",
      800: "#1a202c",
      900: "#171923",
    },
  },
  fonts: {
    heading: "Lato",
    body: "Roboto",
  },
  styles: {
    global: {
      body: {
        bg: "main.background",
        color: "main.text",
        fontFamily: "Roboto, sans-serif",
      },
    },
  },
});

export default customTheme;
