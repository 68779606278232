import { Box, Button, Flex, FormControl, FormLabel, Input, Spacer, Textarea } from "@chakra-ui/react";
import { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, 
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'


interface ModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export default function ModalContacts(props: ModalProps): JSX.Element {

    const [formData, setFormData] = useState({ name: '', contact: '', email: '', message: '' });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const sendEmail = () => {
        console.log('send email')
    };

    return (
        <Modal
            size={{base: 'full', md:'xl', lg:'xl'}}
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader p={{base:'10px 20px', md:'20px 30px', lg:'20px 30px'}} fontSize={'26px'} fontWeight="bold">Entre em contacto connosco.</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={{base:'10px 20px', md:'20px 30px', lg:'20px 30px'}}>
                    <Flex
                        w={'100%'}
                        flexDir="column"
                        bg="white"
                    >
                        <FormControl
                            as="form"
                            autoComplete="off"
                            p="20px 0"
                        >
                            <FormLabel fontWeight="400" lineHeight="160%" fontSize={{ base: '13px', md: '17px', lg: "14px" }}>
                                Nome
                            </FormLabel>
                            <Input
                                errorBorderColor="red.300"
                                minLength={3}
                                p="6"
                                w="full"
                                fontSize={{ base: '13px', md: '20px', lg: "15px" }}
                                outline="none"
                                type="text"
                                borderRadius="4px"
                                border="1px solid #dddddd"
                                placeholder="Digite o seu nome"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            <Spacer height={5} />
                            <Box
                                w="100%"
                                display="flex"
                                flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
                                justifyContent="space-between"
                            >
                                <Box w={{ base: '100%', md: '49%', lg: '49%' }} mb={{ base: '20px', md: '0', lg: '0' }}>
                                    <FormLabel fontWeight="400" lineHeight="160%" fontSize={{ base: '13px', md: '17px', lg: "14px" }}>
                                        Telefone
                                    </FormLabel>
                                    <Input
                                        errorBorderColor="red.300"
                                        minLength={3}
                                        p="6"
                                        w="full"
                                        fontSize={{ base: '13px', md: '20px', lg: "15px" }}
                                        outline="none"
                                        type="tel"
                                        borderRadius="4px"
                                        border="1px solid #dddddd"
                                        placeholder="Digite o seu contacto"
                                        autoComplete="tel"
                                        name="contact"
                                        value={formData.contact}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                                <Box w={{ base: '100%', md: '49%', lg: '49%' }}>
                                    <FormLabel fontWeight="400" lineHeight="160%" fontSize={{ base: '13px', md: '17px', lg: "14px" }}>
                                        Email
                                    </FormLabel>
                                    <Input
                                        errorBorderColor="red.300"
                                        minLength={3}
                                        p="6"
                                        w="full"
                                        fontSize={{ base: '13px', md: '20px', lg: "15px" }}
                                        outline="none"
                                        type="email"
                                        borderRadius="4px"
                                        border="1px solid #dddddd"
                                        placeholder="Digite o seu email"
                                        autoComplete="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </Box>
                            <Spacer height={5} />
                            <FormLabel fontWeight="400" lineHeight="160%" fontSize={{ base: '13px', md: '17px', lg: "14px" }}>
                                Mensagem
                            </FormLabel>
                            <Textarea
                                errorBorderColor="red.300"
                                minLength={3}
                                p="6"
                                w="full"
                                h={{base:"130px",md:"150px", lg:"150px"}}
                                fontSize={{ base: '13px', md: '20px', lg: "15px" }}
                                outline="none"
                                borderRadius="4px"
                                border="1px solid #dddddd"
                                placeholder="Escreva uma mensagem..."
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <Button
                            bg="#000"
                            color="#fff"
                            borderRadius="4"
                            w="100px"
                            h={{ base: '40px', md: '55px', lg: "50px" }}
                            p="5px"
                            _hover={{ bg: '#000' }}
                            isLoading={false}
                            fontSize={['12px', '20px', '16px']}
                            onClick={sendEmail}
                        >
                            Enviar
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}