import { Flex, Text, Image, Link } from "@chakra-ui/react";

export default function Banner(): JSX.Element {
    const clients = [
        {
            logo: './MoMenu_Branco.png',
            link: 'https://momenu.online/',
        },
        {
            logo: './ToquePlay_Logo.png',
            link: 'https://toqueplay.com/',
        },
        {
            logo: './ZEN_TAAK_Horizontal_White.png',
            link: 'https://zentaak.com/',
        },
    ];

    return (
        <Flex
            h="100vh"
            p={{ base: '30px 20px', md: '30px 40px', lg: '30px 40px' }}
            bg="#040502"
            w="100%"
            flexDir="column"
            align="center"
            textAlign="center"
            color="#fff"
            bgImage="./bannertest.gif"
            bgSize="cover"
            bgPosition="center"
            position="relative"
        >
            <Flex
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="rgba(0, 0, 0, 0.3)"
                backdropFilter="blur(3px)"
                zIndex={1}
            />

            <Flex
                position="relative"
                zIndex={2}
                w="100%"
                flexDir="column"
                align="center"
                flex="1"
                justify="center"
            >
                <Text
                    fontSize={{ base: '26pt', md: '30pt', lg: '45pt' }}
                    fontWeight="550"
                    mb="20px"
                    mx={{ base: '0', md: '15%', lg: '15%' }}
                >
                    Do Papel ao digital, seu projecto ganha vida.
                </Text>
                <Text
                    fontSize={{ base: '10pt', md: '13pt', lg: '13pt' }}
                >
                    Agregamos o valor que você procura dar ao seu negócio e mostramos o caminho da prosperidade.
                </Text>
            </Flex>
            <Flex
                direction="column"
                align="center"
                w="100%"
                mt="auto"
                position="relative"
                zIndex={2}
            >
                <Text
                    fontSize={{ base: '10pt', md: '13pt', lg: '13pt' }}
                    pt={4}
                >
                    As pratas da casa
                </Text>
                <Flex
                    justify="center"
                    align="center"
                    w="100%"
                    overflowX="auto"
                    gap={{ base: 5, md: 10, lg: 10 }}
                    sx={{
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {clients.map((client, i) => (
                        <Flex
                            key={i}
                            justify="center"
                            align="center"
                            w={{ base: '200px', md: '100px', lg: '120px' }}
                            h={{ base: '90px', md: '60px', lg: '70px' }}
                        >
                            <Link href={client.link} isExternal mx={2}>
                                <Image src={client.logo} />
                            </Link>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
}
