import { Box, Divider, Flex, Text, Link, Image } from "@chakra-ui/react";
import { /* FaFacebook, */ FaInstagram, /* FaTwitter, */ FaLinkedin } from 'react-icons/fa';

export default function Footer(): JSX.Element {
    return (
        <Box
            as="footer"
            w="100%"
            p="20px"
            bg="#040502"
            color="#fff"
        >
             <Flex flexDir={'row'} align="center" justify="space-around" mb={2}>
                <Image
                    src="./TM_White_Horizontal.png"
                    alt="Logo"
                    h={{base:'30px', md:"40px",lg:"40px"}}
                    objectFit="contain"
                    mb={{base:'10px'}}
                />
               <Flex justify="center" gap={5} mb={4}>
                {/* <Link href="https://www.facebook.com" isExternal mx={2}>
                    <FaFacebook size={26} color="#fff" />
                </Link> */}
                <Link href="https://www.instagram.com/toquemedia/" isExternal mx={2}>
                    <FaInstagram size={26} color="#fff" />
                </Link>
                {/* <Link href="https://www.twitter.com" isExternal mx={2}>
                    <FaTwitter size={26} color="#fff" />
                </Link> */}
                <Link href="https://www.linkedin.com/company/toquemedia/" isExternal mx={2}>
                    <FaLinkedin size={26} color="#fff" />
                </Link>
            </Flex>
            </Flex>
            <Divider borderColor="#fff" mb={3} />
            <Text fontSize={{base:'9pt', md:'11pt', lg:'11pt'}}  textAlign="center">© 2024 - Todos os direitos reservados - Toque Media, Lda</Text>
        </Box>
    );
}
