import { Box, Flex, Image, Text } from "@chakra-ui/react";

export default function OurExperience(): JSX.Element {

    return (
        <Flex
            h={{ base: 'auto', md: 'auto', lg: '600px' }}
            p={{ base: '30px', md: '40px  100px', lg: '40px  100px' }}
            justify={'space-between'}
            my={{ base: '20px', md: '10px', lg: '40px' }}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        >
            <Flex
                w={{ base: '100%', md: '100%', lg: '40%' }}
                h={'100%'}
                justify={'center'}
                flexDir={'column'}
                textAlign={{ base: 'center', md: 'left', lg: 'left' }}
            >
                <Text fontSize={{ base: '20pt', md: '26pt', lg: '28pt' }} mb={'20px'}>
                    Trabalhe com uma equipa vencedora!
                </Text>
                <Text fontSize={{ base: '13pt', md: '16pt', lg: '16pt' }} mb={6}>
                    Nossa equipa é dotada por engenheiros com mais de 20 anos de experiência naquilo que fazem e para si, se o seu desejo é vencer, então a fórmula é simples: Trabalhe com quem sabe ganhar!
                </Text>
                <Image  w={'350px'} p={'15px'} borderRadius={'20px'} src="./Frame_3.png" />
            </Flex>
            <Box
                w={{ base: '100%', md: '100%', lg: '50%' }}
                mt={{ base: '20px', md: '0%', lg: '0%' }}
            >
                <Image h={{ base: '50vh', md: '70vh', lg: '60vh', '2xl':'55vh' }} borderRadius={'20px'} src="./img1.jpg" />
            </Box>
        </Flex>
    )
}
