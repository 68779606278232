import Banner from "./Banner";
import Consultancy from "./Consultancy ";
import Contacts from "./Contacts";
import Footer from "./Footer";
import Header from "./Header";
import OurClients from "./OurClients";
import OurExperience from "./OurExperience";
import OurService from "./OurService";


export default function Home(): JSX.Element {

    return (
        <>
            <Header />
            <Banner />
            <OurExperience />
            <OurService />
            <OurClients />
            {/*  <OurProducts /> */}
            <Consultancy />
            <Contacts />
            <Footer />
        </>
    );
}
