import { Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";

export default function Header(): JSX.Element {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Flex
            align={'center'}
            justify={'space-between'}
            as="header"
            w="100%"
            p={{ base: '30px 20px', md: "20px 50px", lg: "20px 50px" }}
            bg={isScrolled ? "#fff" : ""}
            position="fixed"
            top={0}
            left={0}
            zIndex={10}
            transition="background-color 0.5s ease"
        >
            <Image
                src={isScrolled ? "./TM_Red_Horizontal.png" : "./TM_White_Horizontal.png"}
                alt="Logo"
                h={{base:"30px" ,md: "40px",lg:"40px"}}
            objectFit="contain"
            />
            <Flex
                color={isScrolled ? "red.500" : "#fff"}
                justify={'space-between'}
                fontWeight={'600'}
                fontSize={{base:'10pt' ,md:'13pt' ,lg:'13pt' }}
                pr={{base:'0px',md:'50px' ,lg:'50px'}}
            >
                {/* <Text pr={'50px'}>Quem somos</Text> */}
                <Flex align={'center'}>
                    <a href="/Toque_Media_-_Portfolio.pdf" download>
                        <Flex align={'center'}>
                            <FiDownload size={'13pt'} />
                            <Text px={{base:'5px' ,md:'10px' ,lg:'10px' }}>Baixar portfólio</Text>
                        </Flex>
                    </a>
                </Flex>
            </Flex>
        </Flex>
    );
}
